.message,
.add_img,
.overview {
    width: 100%;
    height: 100vh;
    color: var(--textAlt);
    overflow-y: scroll;
}

.msgContent,
.msgOutlet,
.gallery_dash,
.overviewContent {
    width: 100%;
    padding: 15px;
}

.overviewContent {
    display: grid;
    grid-template-columns: 12fr;
    gap: 20px;
}

.message::-webkit-scrollbar-track,
.add_img::-webkit-scrollbar-track,
.overview::-webkit-scrollbar-track,
.viewMsg.length::-webkit-scrollbar-track,
.newMsg.length::-webkit-scrollbar-track {
    padding: 2px 4px;
    background-color: var(--secondary);
    border-radius: 5px;
}

.message::-webkit-scrollbar,
.add_img::-webkit-scrollbar,
.overview::-webkit-scrollbar,
.viewMsg.length::-webkit-scrollbar,
.newMsg.length::-webkit-scrollbar
 {
    width: 8px;
}

.message::-webkit-scrollbar-thumb,
.add_img::-webkit-scrollbar-thumb,
.overview::-webkit-scrollbar-thumb,
.newMsg.length::-webkit-scrollbar-thumb,
.viewMsg.length::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--primary);
    border: 1px solid #000;
}

.newMessages,
.viewMessages {
    width: 100%;
    background-color: var(--secondary);
    padding: 20px;
    border-radius: 10px;
}

.newMsg.length,
.viewMsg.length {
    height: 320px;
    padding: 10px;
    overflow-y: scroll;
}

.viewMessages {
    margin-top: 2rem;
}

.newMsg,
.viewMsg {
    display: grid;
    grid-template-columns: 6fr 6fr;
    column-gap: 10px;
    row-gap: 10px;
    width: 100%;
}

.newMsgHead,
.viewMsgHead {
    width: 100%;
    text-align: start;
    margin-bottom: 1.5rem;
}

.viewMsgHead,
.newMsgHead {
    display: flex;
    justify-content: space-between;
}

.notMsg {
    align-items: flex-start;
    background-color: var(--primary);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    text-decoration: none;
    color: #ffffff;
}

.msgSender {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.msgSender h3 {
    font-size: 16px;
    color: var(--textAlt);
}

.msgMsg p {
    color: var(--background);
}

.msgSender p {
    font-size: 12px;
    color: green;
}

.msgMsg {
    width: 100%;
    text-align: start;
    height: 20px;
    overflow-y: hidden;
}

.viewMsg .msgMsg p,
.viewMsg .msgSender p,
.viewMsg .msgSender h3 {
    color: #cdcdcd;
}

.msgEdit {
    position: relative;
}

.msgEditMain {
    display: flex;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
}

.editNewInfo,
.edit2 {
    width: 100px;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 100%;
    right: 2%;
    z-index: 2;
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity .7s;
}

.editNewInfo.active,
.edit2.active {
    opacity: 1;
}

.editNewInfo {
    width: 150px;
}

.editInfoMsg span {
    line-height: 35px;
    width: 100%;
    border-radius: 5px;
    padding: 0 5px;
    font-size: 14px;
    display: block;
    cursor: pointer;
}

.editInfoMsg span:hover {
    background-color: var(--secondary);
    color: var(--textAlt);
}

.editMsg {
    color: var(--primary);
    font-size: 20px;
}

.msgVwd h3,
.msgVwd p {
    color: gray;
}

@media (max-width: 525px) {

    .newMsg,
    .viewMsg {
        grid-template-columns: 12fr;
    }
}