.about_me_head {
    width: 100%;
    height: 700px;
    position: relative;
}

.about_my_img {
    width: 100%;
    height: 100%;
    display: flex;
}

.img_main_me {
    width: 70%;
    height: 100%;
    position: relative;
}

.about_me_text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    color: var(--textAlt);
}

.about_me_text h1 {
    font-size: 80px;
    text-shadow: -2px 2px 2px var(--secondary);
}

.img_main_me {
    background: url(../../assets/aboutImg/damian5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: var(--textAlt);
    position: relative;
}

.img_additional_column {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.img_me_row {
    width: 100%;
    height: 50%;
}

.img_me_row img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 928px) {
    .about_me_text h1 {
        font-size: 80px;
    }
}

@media (max-width: 768px) {
    .about_me_head {
        height: fit-content;
    }

    .about_my_img {
        flex-direction: column;
    }

    .img_main_me {
        width: 100%;
        height: 600px;
    }

    .about_me_text {
        display: none;
    }

    .img_additional_column {
        width: 100%;
        height: 400%;
        display: flex;
        flex-direction: row;
    }

    .img_main_me::before {
        content: 'ABOUT ME';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 60px;
        font-weight: 600;
        text-shadow: -2px 2px 2px var(--textAlt);
        white-space: nowrap;
    }
}