.main_gallery_img_head {
    height: 100vh;
}

.main_gallery_img_head_cont {
    width: 100%;
    height: 100%;
    background: url(../../assets/galleryImg/lens.png) scroll;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.main_gallery_img_head_cont::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00111d5a;
    z-index: 1;
}

.main_gallery_img_head_text {
    width: 100%;
    height: 100%;
    color: var(--textAlt);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.main_gallery_img_head_text h1 {
    font-size: 80px;
    text-shadow: -2px 2px 2px var(--secondary);
}

.gallery_main_img_col {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
    gap: 20px;
    width: 100%;
    padding: 5rem;
}

.gallery_main_img_list {
    width: 100%;
    height: 500px;
}

.gallery_main_img_list img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px ) {
    .main_gallery_img_head {
        height: 600px;
    }

    .main_gallery_img_head_text h1 {
        font-size: 60px;
    }

    .gallery_img_main_head, .gallery_main_img_col {
        padding: 4rem 3rem;
    }

    .gallery_main_img_col {
        grid-template-columns: 6fr 6fr;
    }
}

@media (max-width: 575px) {
    .gallery_img_main_head, .gallery_main_img_col {
        padding: 4rem 20px;
    }

    .gallery_main_img_col {
        grid-template-columns: 12fr;
    }
}