main,
.about,
.about_cont,
.gallery,
.gallery_cont,
.gallery_type_and_sample,
.price_and_service,
.price_and_service_cont {
    width: 100%;
    color: var(--text);
}

/* Marquee */
.marquee {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
    padding: 10px 0;
    background-color: var(--primary);
    color: var(--textAlt);
}

.marquee_2 {
    justify-content: flex-end;
}

.marquee_list {
    display: flex;
    align-items: center;
    column-gap: 20px;
    white-space: nowrap;
    padding: 20px 20px 20px 0;
    border-top: 8px double var(--secondary);
    border-bottom: 8px double var(--secondary);
    animation: marq_left 6000ms infinite linear;
}

.marq_2 {
    animation: marq_right 6000ms infinite linear;
}

.marq_icon {
    font-size: 30px;
}

@keyframes marq_left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes marq_right {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

.about_cont,
.gallery_cont,
.price_and_service_cont,
.gallery_img_main_head {
    padding: 5rem;
}

.about_head h1,
.gallery_head h1,
.price_and_service_head h1
.gallery_img_main_head h1 {
    font-size: 50px;
}

.about_text p,
.gallery_img_main_head p {
    font-size: 22px;
}

.about_link, 
.gallery_img_main_book {
    width: 250px;
    height: 60px;
    background-color: var(--primary);
    margin: 24px 0 0;
    box-shadow: -5px 5px 0 var(--textAlt);
}

.about_link a,
.gallery_img_main_book a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textAlt);
    font-weight: 600;
    font-size: 20px;
    text-decoration: none;
}

/* Gallery */
.gallery_type_and_sample {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.gallery_type_list {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.gallery_type_list:nth-child(odd) .gallery_type_head {
    width: 100%;
    text-align: end;
}

.gallery_type_head h3 {
    font-size: 40px;
    position: relative;
    padding-bottom: 10px;
    font-style: italic;
}

.gallery_type_head h3::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 200px;
    height: 2px;
    background-color: var(--secondary);
}

.gallery_type_info,
.gallery_type_image_list,
.gallery_type_images,
.gallery_type_about_text {
    display: flex;
}

.gallery_type_about_text {
    z-index: 3;
}

.gallery_type_about_text h3 {
    font-size: 30px;
    margin-bottom: 10px;
}

.gallery_type_about_text p {
    font-size: 20px;
}

.gallery_type_about_link,
.gallery_type_about_link a {
    display: flex;
    align-items: center;
}

.gallery_type_about_link {
    margin-top: 2rem;
}

.gallery_type_about_link a {
    column-gap: 10px;
    font-size: 20px;
    text-decoration: none;
    color: var(--secondary);
}

.gallery_type_images {
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
}

.gallery_type_list:nth-child(odd) .gallery_type_info:nth-child(odd),
.gallery_type_list:nth-child(even) .gallery_type_info:nth-child(odd) {
    flex-direction: row-reverse;
}

.gallery_type_image_list,
.gallery_type_about_text {
    width: 50%;
}

.gallery_type_about_text {
    height: 600px;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
}

.gallery_type_image_list {
    column-gap: 10px;
}

.gallery_type_img,
.gallery_type_img img {
    width: 100%;
    height: 600px;
}

.gallery_type_img img {
    object-fit: cover;
}

.gallery_type_img_no span {
    font-size: 50px;
    color: var(--secondary);
}

/* Pricing */
.price_and_service_carousel {
    margin-top: 5rem;
}

.price_and_service {
    background-color: var(--secondary);
    color: var(--textAlt);
}

.price_and_service_head h1 {
    color: var(--textAlt);
}

.price_and_service_carousel {
    column-gap: 20px;
}

.price_and_service_list {
    width: 350px !important;
    height: fit-content;
}

.price_and_service_image,
.price_and_service_image img {
    width: 100%;
    height: 250px;
}

.price_and_service_image img {
    object-fit: cover;
}

.price_and_service_info {
    width: 100%;
    height: fit-content;
    padding: 20px 10px;
    background-color: var(--textAlt);
}

.price_and_service_info_head {
    margin-bottom: 1rem;
}

.price_and_service_info_head h3 {
    font-size: 25px;
}

.price_and_service_info_details {
    height: 150px;
    overflow: hidden;
}

.price_and_service_info_details ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
}

.price_and_service_info_details ul li {
    list-style: none;
}

.price_and_service_info_details ul li::before {
    content: '>>';
}

@media (max-width: 780px) {
    .about_cont,
    .gallery_cont,
    .price_and_service_cont {
        padding: 4rem 3rem;
    }

    .gallery_type_image_list {
        width: 60%;
    }

    .gallery_type_about_text {
        width: 40%;
    }

    .gallery_type_about_text {
        padding: 30px;
    }
}

@media (max-width: 695px) {
    .gallery_type_info {
        position: relative;
    }

    .gallery_type_image_list {
        width: 100%;
    }

    .gallery_type_about_text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: var(--textAlt);
        background-color: #00111d28;
    }

    .gallery_type_img_no {
        display: none;
    }

    .gallery_type_about_text h3 {
        font-size: 25px;
    }

    .gallery_type_about_text p {
        font-size: 16px;
    }

    .gallery_type_about_text {
        padding: 20px;
    }
}

@media (max-width: 575px) {
    .about_cont,
    .gallery_cont,
    .price_and_service_cont {
        padding: 4rem 20px;
    }
}