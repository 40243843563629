nav {
    width: 100%;
    height: 100px;
}

.nav_cont, .nav_logo, .nav_menu, .nav_close, .nav_links, .nav_links li, .nav_links li a {
    display: flex;
    align-items: center;
}

.nav_cont {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 0 5rem;
    color: var(--textAlt);
    border-bottom: 8px double var(--textAlt);
    background-color: var(--secondary);
}

.nav_logo {
    text-decoration: none;
    color: var(--textAlt);
}

.nav_logo h3 {
    font-style: italic;
}

.nav_links {
    column-gap: 30px;
}

.nav_links li a {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: var(--textAlt);
    text-decoration: none;
}

.nav_close, .nav_burger {
    display: none;
}

@media (max-width: 928px) {
    nav {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 10;
    }

    .nav_close, .nav_burger {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        border: 2px solid var(--textAlt);
        color: var(--textAlt);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav_burger, .nav_close {
        font-size: 20px;
    }

    .nav_close {
        margin: 20px 20px 0 auto;
    }

    .nav_menu {
        position: fixed;
        top: 0;
        left: -100vw;
        bottom: 0;
        z-index: 10;
        width: 70vw;
        height: 100%;
        background-color: var(--primary);
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
        transition: left .7s;
    }

    .nav_menu.active {
        left: 0;
    }

    .nav_links {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
        width: 100%;
        padding: 20px;
    }

    .nav_links li a {
        font-size: 30px;
    }
}

@media (max-width: 780px) {
    .nav_cont {
        padding: 0 3rem;
    }
}

@media (max-width: 575px) {
    .nav_cont {
        padding: 0 20px;
    }
}